const { REACT_APP_API_ENV } = process.env

export const URLS = {
  production: {
    MS_PROVI_PAY: 'https://ms-provipay.provi.com.br/v2',
    MS_PASSWORDLESS: 'https://ms-passwordless.provi.com.br',
    BASE_URL_V3: 'https://pay.principia.net',
  },
  staging: {
    MS_PROVI_PAY: 'https://ms-provipay-staging.provi.com.br/v2',
    MS_PASSWORDLESS: 'https://ms-passwordless-staging.provi.com.br',
    BASE_URL_V3: 'https://pay-staging.principia.net',
  },
}

export const setEnvironment = (environment) => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
      return URLS.staging
    case 'development':
      return URLS.development
    default:
      return URLS.development
  }
}

export const WHATSAPP_LINK =
  'https://api.whatsapp.com/send?phone=5511957712414&text=(Provipay)%20Oi%2C%20Provi%21%20Preciso%20de%20ajuda'

/** @type {Record<'MS_PROVI_PAY' | 'MS_PASSWORDLESS' | 'BASE_URL_V3', string>} */
export const ROOT_URL = setEnvironment(REACT_APP_API_ENV || 'development')
