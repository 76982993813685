import React, { createContext, useState, useCallback, useContext } from 'react'
import { placeAddress, proccessPayment, placeCourseFinancingOrder, getCheckoutCart, createCheckout } from '~/services/api'
import { useGeolocation, useLoading } from '~/hooks'
import '~/typings'
import { setEvent, getSlugPartner, handleAxiosError, smartlookSender } from '~/utils'
import { eventActions, eventCategories } from '~/enums'
import { history } from '~/navigation/history'
import { showToast } from '@provi/provi-components'

/** @typedef {(slug: string, cpf: string, courses: number[], campaign?: string) => Promise<void>} GetCheckoutInfoFN */

/** @typedef {(params: { CPF: string, email: string, paymentMethod: string, campaignSlug: string }) => Promise<void>} CreateCheckoutFN */

/** @typedef {(params: { customer: ICustomer, partnerCondition: number, hash: string, holder: IHolder }, type: 'CreditCard' | 'Boleto') => Promise<IPaymentSuccessRes>} ProcessPaymentFN */

/**
 * @typedef {object} IChecktoutState
 * @property {ICheckoutCartInfo} checkoutCartData
 * @property {GetCheckoutInfoFN} getCheckoutCartInfo
 * @property {number[]} selectedCourses
 * @property {React.Dispatch<React.SetStateAction<number[]>>} setSelectedCourses
 * @property {string} cpf
 * @property {string} creditRequest
 * @property {React.Dispatch<React.SetStateAction<string>>} setCPF
 * @property {boolean} showDiscountAlert
 * @property {boolean} shouldShowDiscountAlert
 * @property {(param: booelan) => void} triggerDiscountAlert
 */

/** @type {IChecktoutState} */
const Context = createContext({})

/** @typedef {React.FC} */
export const CheckoutProvider = ({ children }) => {
  const { setIsLoading, setIsSmallLoading } = useLoading()
  const { latitude, longitude } = useGeolocation()

  const [showDiscountAlert, setShowDiscountAlert] = useState(false)
  const [shouldShowDiscountAlert, setShouldShowDiscountAlert] = useState(true)
  const [creditRequest, setCreditRequest] = useState(0)
  const [cpf, setCPF] = useState('')
  const [selectedCourses, setSelectedCourses] = useState([])
  const [checkoutCartData, setCheckoutCartData] = useState({})
  const [addressSuccessData, setAddressSuccessData] = useState({})
  const [paymentCreditAndBoletoSuccessData, setPaymentCreditAndBoletoSuccessData] = useState({})
  const [paymentCourseFinancingSuccessData, setPaymentCourseFinancingSuccessData] = useState({})
  const [continueRequestData, setContinueRequestData] = useState({})
  const [selectedModality, setSelectedModality] = useState('')
  const [holderInformation, setHolderInformation] = useState({})
  const [informationBody, setInformationBody] = useState({})
  const [addressBody, setAddressBody] = useState({})
  const [creditBody, setCreditBody] = useState({})
  const [refusedContinueRequest, setRefusedContinueRequest] = useState(false)
  const [cartInformations, setCartInformations] = useState({})
  const [step, setStep] = useState(1)
  const [maxStep, setMaxStep] = useState(1)

  /** @type {(show: boolean) => void} */
  const triggerDiscountAlert = useCallback(
    (show) => {
      if (show) {
        setShowDiscountAlert(true)
      } else {
        setShowDiscountAlert(false)
        setShouldShowDiscountAlert(false)
      }
    },
    [checkoutCartData]
  )

  /** @type {GetCheckoutInfoFN} */
  const getCheckoutCartInfo = useCallback(
    async (slug, cpf, courses, campaign, p) => {
      setIsLoading(true)
      try {
        if ((courses && courses.length) || campaign || cpf) {
          const data = await getCheckoutCart({ slug, cpf, courses, campaignSlug: campaign, p })
          if (data?.courseIds?.length !== 0 && campaign) {
            data.courseIds.forEach((courseId) => {
              setEvent(eventCategories.cart, {
                label: campaign,
                value: courseId,
                action: eventActions.addFromCampaign,
              })
            })
          }

          setCheckoutCartData(data)
          if (data.discountAlert && data.discountAlert.length > 0) {
            return triggerDiscountAlert(true)
          }
        }
      } finally {
        setIsLoading(false)
      }
    },
    [setCheckoutCartData, setIsLoading, shouldShowDiscountAlert]
  )

  const createPlaceAddress = useCallback(
    async ({ address }) => {
      try {
        setIsLoading(true)
        creditRequest != address.creditRequestId && setCreditRequest(address.creditRequestId)

        const addressBody = {
          CreditRequestId: address.creditRequestId || creditRequest,
          zipcode: address.zipcode,
          state: address.state || 'AC',
          street: address.street,
          district: address.district,
          city: address.city,
          streetNumber: address.streetNumber,
        }

        if (address.complement) addressBody.complement = address.complement

        setAddressBody(addressBody)
        const data = await placeAddress(addressBody)

        setAddressSuccessData(data)

        if (data) {
          setMaxStep(3)
          setStep(3)
        }

        return data
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setAddressSuccessData, creditRequest, setStep, setMaxStep]
  )

  const processPaymentCreditAndBoleto = useCallback(
    async ({ paymentMethod, PartnerConditionId, CreditRequestId, creditCardInformation }) => {
      try {
        setIsLoading(true)

        if (paymentMethod == 'CreditCard') {
          const creditCardPaymentBody = {
            CreditRequestId,
            PartnerConditionId,
            paymentMethod,
            creditCardInformation,
            device: {
              geolocation: { latitude: latitude, longitude: longitude },
              userAgent: navigator.userAgent,
            },
          }

          const creditData = await proccessPayment(creditCardPaymentBody)

          creditData && setPaymentCreditAndBoletoSuccessData(creditData)
          creditData.success == true && history.push('/success')
          !creditData.success && showToast(creditData.description)
          creditData.error && showToast(creditData.errors[0].message)

          creditData.success == true && cleanAllStores()
          return creditData
        }

        const boletoPaymentBody = {
          CreditRequestId,
          PartnerConditionId,
          paymentMethod,
        }

        const boletoData = await proccessPayment(boletoPaymentBody)

        boletoData && setPaymentCreditAndBoletoSuccessData(boletoData)
        boletoData.success == true && history.push('/success')
        !boletoData.success && showToast(boletoData.description)

        boletoData.success == true && cleanAllStores()
        return boletoData
      } catch (error) {
        showToast(handleAxiosError(error))
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, proccessPayment, setPaymentCreditAndBoletoSuccessData, latitude, longitude]
  )

  const processPaymentCourseFinancing = useCallback(
    async ({ PartnerConditionId, CreditRequestId }) => {
      try {
        setIsLoading(true)

        const courseFinancingPaymentBody = {
          PartnerConditionId,
          CreditRequestId,
        }

        const courseFinancingData = await placeCourseFinancingOrder(courseFinancingPaymentBody)

        courseFinancingData && setPaymentCourseFinancingSuccessData(courseFinancingData)
        courseFinancingData.success == true && !courseFinancingData.isOTP && history.push('/success')

        courseFinancingData.success == true &&
          courseFinancingData.isOTP == true &&
          window.location.replace(
            `https://login.principia.net/verificacao?parceiro=${courseFinancingData.partnerSlug}&email=${courseFinancingData.email}`
          )

        !courseFinancingData.success && showToast(courseFinancingData.description)

        courseFinancingData.success == true && cleanAllStores()
        return courseFinancingData
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, placeCourseFinancingOrder, setPaymentCourseFinancingSuccessData]
  )
  const getCheckoutData = useCallback(
    async ({ userCpf, courses, campaignSlug, slug, p }) => {
      setIsSmallLoading(true)

      try {
        const body = {
          cpf: userCpf,
          courses,
          campaignSlug,
          slug,
        }

        p && (body.p = p)

        const data = await getCheckoutCart(body)
        setCartInformations(data)

        //if existing request
        if (!creditRequest && data.existingRequests && data.existingRequests.length > 0 && !refusedContinueRequest) {
          const backObject = {
            courses: data.existingRequests,
            cpf: userCpf,
            PartnerId: data.PartnerId,
            PartnerName: data.partnerName,
          }
          p && (body.p = p)

          setIsSmallLoading(false)
          return { continueRequest: backObject }
        }

        setIsSmallLoading(false)

        if (data.discountAlert && data.discountAlert.length > 0) {
          return triggerModalDiscountAlert(true)
        }

        return data
      } catch (error) {
        showToast(handleAxiosError(error))
        setIsSmallLoading(false)
      }
    },
    [cartInformations, creditRequest, refusedContinueRequest]
  )

  const triggerModalDiscountAlert = useCallback(
    (show) => {
      if (show) {
        setShowDiscountAlert(true)
      } else {
        setShowDiscountAlert(true)
        setShouldShowDiscountAlert(false)
      }
    },
    [cartInformations]
  )

  const createNewCheckout = useCallback(
    async (postBody) => {
      setIsLoading(true)

      try {
        const data = await createCheckout(postBody)
        creditRequest != data.CreditRequestId && setCreditRequest(data.CreditRequestId)
        setIsLoading(false)
        setCartInformations({ ...cartInformations, CreditRequestId: data.CreditRequestId })
        setStep(2)
        setMaxStep(2)
        data && smartlookSender({ crid: data.CreditRequestId, email: postBody.email, partnerSlug: getSlugPartner() })
      } catch (error) {
        setIsLoading(false)
        showToast(handleAxiosError(error))
      }
    },
    [cartInformations]
  )

  const updateCheckout = useCallback(
    async (body) => {
      setIsSmallLoading(true)

      try {
        return await createCheckout(body)
      } catch (error) {
        setIsSmallLoading(false)
        showToast(handleAxiosError(error))
        return { error: error.response.data.errors[0].message }
      } finally {
        setIsSmallLoading(false)
      }
    },
    [setIsSmallLoading]
  )

  const cleanAllStores = useCallback(() => {
    setHolderInformation({})
    setInformationBody({})
    setAddressBody({})
    setCreditBody({})
    setRefusedContinueRequest(false)
    setStep(1)
    setMaxStep(1)
  }, [])

  return (
    <Context.Provider
      value={{
        checkoutCartData,
        selectedCourses,
        setSelectedCourses,
        showDiscountAlert,
        shouldShowDiscountAlert,
        creditRequest,
        cpf,
        setCPF,
        getCheckoutCartInfo,
        triggerDiscountAlert,
        createPlaceAddress,
        addressSuccessData,
        processPaymentCreditAndBoleto,
        paymentCreditAndBoletoSuccessData,
        processPaymentCourseFinancing,
        paymentCourseFinancingSuccessData,
        continueRequestData,
        setContinueRequestData,
        selectedModality,
        setSelectedModality,
        getCheckoutData,
        updateCheckout,
        setCartInformations,
        cartInformations,
        step,
        setStep,
        maxStep,
        setMaxStep,
        triggerModalDiscountAlert,
        createNewCheckout,
        holderInformation,
        setHolderInformation,
        refusedContinueRequest,
        setRefusedContinueRequest,
        informationBody,
        setInformationBody,
        addressBody,
        setAddressBody,
        creditBody,
        setCreditBody,
        setAddressSuccessData,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * @name useCourses
 * @returns {IChecktoutState}
 */
export function useCheckout() {
  return useContext(Context)
}
